<template>
    <v-card flat class="mb-7 w-100">
        <v-card-text class="pa-5">
            <span class="lstick"></span>
            <h3 class="title font-weight-regular">Comentarios Recientes</h3>
        </v-card-text>
        <v-virtual-scroll
                :items="comments"
                :item-height="70"
                height="400">
            <template v-slot:default="{ index, item }">
                <ItemComment :item="item" :key="index" :index="index" :count="comments.length" @reload="getComments"/>
            </template>
        </v-virtual-scroll>
    </v-card>
</template>

<script>
    import dashboardService from '@/providers/DashboardService';
    import ItemComment from "@/components/dashboards/recent-comments/ItemComment.vue";
    
    export default {
        name: "TheRecentComments",
      components: {ItemComment},
        props: {
            comment: Object,
        },
        data: () => ({
            comments: [],
           /* comments: [
                {
                    img: "/1.jpg",
                    activeClass: "",
                    username: "James Anderson",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "blue lighten-4 blue--text text--darken-1",
                    status: "Pending",
                },
                {
                    img: "/2.jpg",
                    activeClass: "active",
                    username: "Michael Jorden",
                    desc:
                        "Lorem Ipsum has beenorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "green lighten-4 green--text text--darken-1",
                    status: "Approved",
                },
                {
                    img: "/3.jpg",
                    activeClass: "",
                    username: "Johnathan Doeting",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "red lighten-4 red--text text--darken-1",
                    status: "Rejected",
                },
                {
                    img: "/4.jpg",
                    activeClass: "",
                    username: "James Anderson",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "April 14, 2020",
                    badgebg: "blue lighten-4 blue--text text--darken-1",
                    status: "Pending",
                },
                {
                    img: "/5.jpg",
                    activeClass: "",
                    username: "Nirav joshi",
                    desc:
                        "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
                    date: "August 14, 2020",
                    badgebg: "amber lighten-4 amber--text text--darken-1",
                    status: "Pending",
                },
            ],*/
        }),
        methods: {
            getComments() {
                dashboardService.getRecientComments().then(record => {
                    this.comments = record.value;
                });
            },
        },
        mounted() {
            this.comments = [];
            this.getComments();
        }
    };
</script>