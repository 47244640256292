<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-avatar
          :color="getColor()"
          size="56"
          class="white--text">
        {{ item.name | initials }}
      </v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="item.name"></v-list-item-title>
      <v-list-item-subtitle v-html="item.comment"></v-list-item-subtitle>
      <!--<v-card-text v-html="item.comment"></v-card-text>-->
      <v-list-item-subtitle class="mt-2 grey--text">
        <span><small>Posteado en {{ item.created_at | formatDate }}</small></span>
      </v-list-item-subtitle>
      <v-divider v-if="index < count - 1" :key="index"></v-divider>
    </v-list-item-content>

    <v-list-item-action v-if="user.roles == 'ADMIN' || user.roles == 'COPARMEX'">
      <v-btn plain
             depressed
             icon
             @click="openDialog"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-list-item-action>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 headline">
          ¿Desea eliminar este comentario?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" @click="deleteAction">
            Proceder
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import moment from "moment/moment";
import dashboardService from '@/providers/DashboardService';

export default {
  name: "ItemComment",
  props: ["item", "index", "count"],
  data(){
    return {
      user: {},
      dialog: false,
    }
  },
  filters: {
    formatDate (value) {
      if (value) {
        return moment(String(value)).locale('es').format('MMM D, YYYY [a las] hh:mm a');
      }
    },
    initials(value) {
      const name = value.split(' ');
      return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
    },
    trimText(value) {
      const name = value.trim();
      return name;
    }
  },
  methods: {
    openDialog(){
      this.dialog = true;
    },
    closeDialog(){
      this.dialog = false;
    },
    deleteAction(){
      dashboardService.deleteComment(this.item).then(resp=>{
        if(resp.data.success){
          this.$emit('reload');
        }
      }).catch(err=>console.log(err));
    },
    genRandomIndex (length) {
      return Math.ceil(Math.random() * (length - 1))
    },
    getColor() {
      let colors = [
        "info",
        "success",
        "primary",
        "teal",
        "deep-purple accent2",
        "red lighten-1",
        "pink darken-2",
        "grey darken-2",
        "brown",
        "blue-grey darken-3",
        "red darken-3",
        "green"
      ];
      const colorsLength = colors.length;
      let colorsel = this.genRandomIndex(colorsLength);
      return colors[colorsel];
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
  }
}
</script>

<style scoped>

</style>